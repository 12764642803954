@font-face {
  font-family: bangla;
  src: url(bangla-mn.ttf);
}

body {
  margin: 0;
  /* background-color: blue; */
  font-family: bangla;
}
