.App {
  text-align: center;
}

.App-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 12.5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-color: white;
}

.App-header .title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.subtitle {
  font-weight: 100;
}

.App-content {
  width: 90vw;
  margin-top: 15.5vh;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  /* background-color: lightskyblue; */
  margin-bottom: 8vh;
}

.row {
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
}

@media screen and (min-width: 1000px) {
  .row {
    flex-direction: row;
  }
}

@media screen and (max-width: 999px) {
  .row {
    flex-direction: column;
  }
}

.column {
  width: calc(100% - 40px);
  padding-left: 20px;
  margin-bottom: 20px;
}

.column.last {
  padding-right: 20px;
}

.App-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: fixed;
  width: 100vw;
  left: 0;
  bottom: 0;
  max-height: 5vh;
}

.App-footer .row {
  margin-bottom: 0px;
}

.image {
  width: 100%;
  margin-bottom: 15px;
}

.image:last-child {
  margin-bottom: 0px;
}

.font-regular {
  font-weight: 100;
  font-size: 48px;
}

.font-bold {
  font-weight: 900;
  font-size: 48px;
}
